import Swiper from 'swiper';
import { add_swiper_class } from '@arechesbeaufort/js/tools/swiper';

export default class Slider {

    constructor()
    {
        Slider.hero();
        Slider.related();
        Slider.related_sit();
    }

    static hero()
    {
        const slider         = '[data-id="wpiris-hero-slider"]',
            slider_wrapper = '[data-id="wpiris-hero-slider-wrapper"]',
            slider_item    = '[data-id="wpiris-hero-slider-item"]';

        if ( $( slider ).length > 0 ) {

            add_swiper_class( $( slider ), $( slider_wrapper ), $( slider_item ) );

            const hero_slider = new Swiper( $( slider ), {
                init: false,
                autoplay: true,
                speed: 1000,
                slidesPerView: 1,
                loop: true,
                lazy: true,
                navigation: {
                    prevEl: $( slider ).find('.swiperArrow--prev'),
                    nextEl: $( slider ).find('.swiperArrow--next')
                },
                pagination: {
                    el: $( slider ).find('.swiperPagination--fraction'),
                    type: 'fraction',
                    formatFractionCurrent: (number) => {
                        return `${number}`.padStart(2, '0');
                    },
                    formatFractionTotal: (number) => {
                        return `${number}`.padStart(2, '0');
                    },
                },
            } );

            // Set image navigation on slide change
            hero_slider.on('init slideChangeTransitionStart', function() {
                const swiper = this;
                const total_slides = $(swiper.el).find('.swiper-slide:not(.swiper-slide-duplicate)').length;

                // Prev
                if ( swiper.navigation.prevEl ) {

                    let prev_index = swiper.realIndex - 1;

                    if ( prev_index < 0 ) {
                        prev_index = total_slides - 1;
                    }

                    const prev_image_nav = $(swiper.el).find('[data-swiper-slide-index="' + prev_index + '"]').data('image-nav');

                    $(swiper.navigation.prevEl).find('img').attr('src', prev_image_nav);
                }

                // Next
                if ( swiper.navigation.nextEl ) {

                    let next_index = swiper.realIndex + 1;

                    if ( next_index > total_slides - 1 ) {
                        next_index = 0;
                    }

                    const next_image_nav = $(swiper.el).find('[data-swiper-slide-index="' + next_index + '"]').data('image-nav');

                    $(swiper.navigation.nextEl).find('img').attr('src', next_image_nav);
                }
            });

            hero_slider.init();
        }
    }

    static related()
    {
        const slider = '[data-id="related-slider"]';

        if ( $( slider ).length > 0 ) {

            $(slider).each( function() {
                
                new Swiper( $(this), {
                    slidesPerView: 2,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    speed: 600,
                    lazy: true,
                    spaceBetween: 10,
                    freeMode: true,
                    freeModeSticky: true,
                    grabCursor: true,
                    navigation: {
                        prevEl: $(this).find('.swiperArrow--prev'),
                        nextEl: $(this).find('.swiperArrow--next')
                    },
                    pagination: {
                        el: $(this).find('.swiperPagination--fraction'),
                        type: 'fraction',
                        formatFractionCurrent: (number) => {
                            return `${number}`.padStart(2, '0');
                        },
                        formatFractionTotal: (number) => {
                            return `${number}`.padStart(2, '0');
                        },
                    },
                    breakpoints: {
                        651: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        1001: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        }
                    }
                } );
            } );
        }
    }

    static related_sit()
    {
        const slider = '[data-id="related-slider-sit"]';

        if ( $( slider ).length > 0 ) {

            $(slider).each( function() {
                
                new Swiper( $(this), {
                    slidesPerView: 2,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    speed: 600,
                    lazy: true,
                    spaceBetween: 10,
                    freeMode: true,
                    freeModeSticky: true,
                    grabCursor: true,
                    navigation: {
                        prevEl: $(this).find('.swiperArrow--prev'),
                        nextEl: $(this).find('.swiperArrow--next')
                    },
                    pagination: {
                        el: $(this).find('.swiperPagination--fraction'),
                        type: 'fraction',
                        formatFractionCurrent: (number) => {
                            return `${number}`.padStart(2, '0');
                        },
                        formatFractionTotal: (number) => {
                            return `${number}`.padStart(2, '0');
                        },
                    },
                    breakpoints: {
                        651: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        1001: {
                            slidesPerView: 4,
                            spaceBetween: 40
                        }
                    }
                } );
            } );
        }
    }

    static post()
    {
        const slider = '[data-id="post-slider"]';

        if ( $( slider ).length > 0 ) {

            $(slider).each( function() {

                new Swiper( $( this ), {
                    slidesPerView: 1,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    speed: 600,
                    lazy: true,
                    spaceBetween: 40,
                    freeMode: true,
                    freeModeSticky: true,
                    grabCursor: true
                } );
            } );
        }
    }
}
