import '@arechesbeaufort/scss/app-front-page.scss';
import Utils from '@arechesbeaufort/js/services/utils';
import Form from '@arechesbeaufort/js/services/form';
import Slider from '@arechesbeaufort/js/services/slider';
// import BookingForm from '@arechesbeaufort/js/services/booking-form';
import Responsive from '@arechesbeaufort/js/services/responsive';
import FlashMessage from '@arechesbeaufort/js/services/flash-message';

document.addEventListener( "DOMContentLoaded", function () {

    // Utils
    Utils.click();
    Utils.bootstrap();
    Utils.searchbox();
    Utils.smooth_scroll();
    Utils.lazy();
    Utils.obfuscation();
    Utils.on_scroll();
    Utils.google_translate();
    Utils.progress_bar();
    Utils.header_navbar();
    Utils.ajax_instagram();

    // Slider
    Slider.hero();
    Slider.related();
    Slider.post();

    // Specific
    Form.form_agenda();
    // new BookingForm();
    Responsive.init();
    FlashMessage.init();

    // Utils.replace_broken_image();
})
