export default class FlashMessage {

    static flashmsg      = $('.flashMessageText');
    static flashmsgItems = $('.flashMessageItems');
    static flashmsgSpeed = 10;
    static animation     = null;

    constructor()
    {
        FlashMessage.init();
    }

    static init()
    {
        if ($(FlashMessage.flashmsg).length) {
            FlashMessage.startAnimation();
        }

        $(FlashMessage.flashmsg).mouseenter( () => {
            FlashMessage.stopAnimation();
        });

        $(FlashMessage.flashmsg).mouseleave( () => {
            FlashMessage.startAnimation();
        });
    }

    static startAnimation()
    {
        FlashMessage.animation = window.setInterval(FlashMessage.scrollFlashmsg, FlashMessage.flashmsgSpeed);
    }

    static stopAnimation()
    {
        window.clearInterval(FlashMessage.animation);
    }

    static scrollFlashmsg()
    {
        if ($(FlashMessage.flashmsgItems).position().left > -($(FlashMessage.flashmsgItems).outerWidth())) {
            $(FlashMessage.flashmsgItems).css({
                left: $(FlashMessage.flashmsgItems).position().left - 1
            });
        } else {
            $(FlashMessage.flashmsgItems).css({
                left: $(FlashMessage.flashmsg).outerWidth()
            });
        }
    }
}