export default class Form {
	
	constructor()
	{
		Form.select();
	}
	
	static select()
	{
		$( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
	}

	static form_agenda()
	{
	    $('.js-form-agenda-value').on('click', function(e) {
	        e.preventDefault();

	        const value = $(this).data('value');

	        $('.js-form-agenda .period_value').val( value );
	        $('.js-form-agenda').submit();
	    });
	}
}