import '@fancyapps/fancybox/dist/jquery.fancybox.min';
//import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/tooltip';
import Modernizr from 'modernizr';
import LazyLoad from "vanilla-lazyload";


export default class Utils {
    constructor()
    {
        Utils.click();
        Utils.bootstrap();
        Utils.fancybox();
        Utils.searchbox();
        Utils.smooth_scroll();
        Utils.lazy();
        Utils.block_cover();
        Utils.obfuscation();
        Utils.on_scroll();
        Utils.google_translate();
        Utils.ajax_instagram();
        Utils.progress_bar();
        Utils.blog_tags();
        Utils.header_navbar();
        Utils.show_more();
        Utils.comments_rating();
        Utils.browser_hacks();
        Utils.jquery_extends();
        Utils.toggle_tags();

        // Utils.replace_broken_image();
    }

    static replace_broken_image()
    {
        // For local environment only
        if ( window.location.hostname.match(/.iris.io/) ) {
            $('img').on('error', function(e) {
                let src = $(this).attr('src');
                // $(this).attr('src', 'https://unsplash.it/800/450');
                $(this).attr('src', src.replaceAll( window.location.hostname, 'arechesbeaufort.preprod5.irislab.top' ));
            });
        }
    }

    static browser_hacks()
    {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if ( isSafari ) {
            $('html').addClass('is-safari');
        }
    }
    
    static click()
    {
    
        const trigger = '[data-trigger="wpiris-click"]';
        
        if ( !Modernizr.touchevents ) {
            let ctrl = 0;
            $( window ).on( 'keydown', function ( e ) {
                if ( e.which == 17 || e.which == 91 ) {
                    ctrl = true
                }
            })
            $( window ).on( 'keyup', function () {
                ctrl = false
            })
            
            $( document ).on( 'mouseup', trigger, function ( e ) {

                if( e.target.tagName.toLowerCase() !== 'a' ) {
                    if (e.which == 1 || e.which == 2) {
                        let blank = false
                        let url = $(this).find("a").attr("href")
                        if (e.which == 2) {
                            blank = true
                        } // Clic molette
                        if ($(this).find("a").attr("target") == "_blank") {
                            blank = true
                        }
                        if (blank || ctrl) {
                            window.open(url, '_blank')
                        } else {
                            window.location = url
                        }
                        return false
                    }
                }
                return false

            } )
        } else {
            $( document ).on( 'click', trigger, function ( e ) {
                
                let blank = false
                let url   = $( this ).find( "a" ).attr( "href" )
                if ( $( this ).find( "a" ).attr( "target" ) == "_blank" ) {
                    blank = true
                }
                if ( blank ) {
                    window.open( url, '_blank' )
                } else {
                    window.location = url
                }
                return false
                
            } )
        }
    }
    
    static bootstrap()
    {
        if ( !Modernizr.touchevents ) {
            $( '[data-toggle="tooltip"]' ).tooltip( { trigger: "hover" } )
        }

        $( '[data-toggle="popover"]' ).popover();
    }
    
    static fancybox()
    {
        const fancybox_options = {
            buttons: [
                "zoom",
                "slideShow",
                "close"
            ],
            lang: "fr",
            i18n: {
                fr: {
                    CLOSE: Theme.translate.fancybox_close,
                    NEXT: Theme.translate.fancybox_next,
                    PREV: Theme.translate.fancybox_prev,
                    ERROR: Theme.translate.fancybox_error,
                    PLAY_START: Theme.translate.fancybox_start,
                    PLAY_STOP: Theme.translate.fancybox_stop,
                    FULL_SCREEN: Theme.translate.fancybox_full_screen,
                    THUMBS: Theme.translate.fancybox_thumbs,
                    DOWNLOAD: Theme.translate.fancybox_download,
                    SHARE: Theme.translate.fancybox_share,
                    ZOOM: Theme.translate.fancybox_zoom
                },
            },
            caption: function ( instance, item ) {
                let caption = $( this ).closest( 'figure' ).find( 'figcaption' ).html()

                if ( !caption ) {
                    caption = $( this ).find( 'img' ).attr( 'title' )
                }

                if ( !caption ) {
                    caption = $( this ).find( 'img' ).attr( 'alt' )
                }

                return caption
            }
        };

        $( '.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")' ).attr( 'data-fancybox', 'fancybox' );

        let counter = 0;
        $( '.hentry .wp-block-gallery' ).each( function () {
            counter++;
            $( this ).find( 'a:has(>img):not([target="_blank"])' ).attr( 'data-fancybox', 'wp-block-gallery-' + counter );
        } );

        counter = 0;
        $( '.hentry .wp-block-image' ).each( function () {
            counter++;
            $( this ).find( 'a:has(>img):not([target="_blank"])' ).attr( 'data-fancybox', 'wp-block-image-' + counter );
        } );
        
        $( '[data-fancybox]' ).fancybox( fancybox_options )

        // Global page gallery
        $( '.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")' ).attr( 'data-fancybox-gallery-page', '' );

        if ( $('[data-fancybox-gallery-page]').length ) {
            $('.js-show-gallery-page').show();
        }

        $('.js-show-gallery-page').on('click', function(e) {
            e.preventDefault();

            $.fancybox.open( $('[data-fancybox-gallery-page]'), fancybox_options );
        });
    }
    
    static searchbox()
    {
        const trigger  = '[data-trigger="wpiris-search-layout"]',
              close    = '[data-trigger="wpiris-search-layout-close"]',
              $element = $('[data-id="wpiris-search-layout"]');
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $('body').addClass('is-search-active');
            setTimeout( function() {
                $element.find('input').focus();
            }, 300);
        } )
        
        $( document ).on( 'click', close, function ( e ) {
            e.preventDefault();
            $('body').removeClass('is-search-active');
        } )
    }
    
    static smooth_scroll()
    {
        const trigger = '[data-trigger="wpiris-scroll"]';

        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();

            const target = $(this).attr("href") || $(this).attr("data-href"),
                duration = 1000,
                scroll_top = $( target ).offset().top - ( undefined !== e.currentTarget.dataset.shift ? e.currentTarget.dataset.shift : 0 );

            $( 'html, body' ).animate( {
                scrollTop: scroll_top
            }, {
                duration: duration,
                step: ( now, fx ) => {
                    let real_pos = scroll_top;
                    if ( fx.end !== real_pos ) {
                        fx.end = real_pos;
                    }
                }
            });
        })
    }
    
    static lazy()
    {
        const lazyLoadInstance = new LazyLoad({
            elements_selector: '[data-src], [data-bg], [data-bg-hidpi]'
        });
        window.onbeforeprint = function () {
            lazyLoadInstance.loadAll();
        };
    }

    static block_cover() {
        $( '.wp-block-cover:has(.wp-block-cover__inner-container a)' ).attr( 'data-trigger', 'wpiris-click' )
    }
    
    static obfuscation()
    {
        let element = "[data-obf]";
        if ( $( element ).length > 0 ) {
            if ( !Modernizr.touchevents ) {
                let ctrl = 0;
                $( window ).on( 'keydown', function ( e ) {
                    if ( e.which == 17 || e.which == 91 ) {
                        ctrl = true;
                    }
                } );
                $( window ).on( 'keyup', function () {
                    ctrl = false;
                } );
                
                $( document ).on( 'mouseup', element, function ( e ) {
                    let url    = atob( $( this ).attr( 'data-obf' ) );
                    let target = $( this ).attr( 'data-obf-target' );
                    if ( e.which == 1 || e.which == 2 ) {
                        let blank = false;
                        if ( e.which == 2 ) {
                            blank = true;
                        } // Clic molette
                        if ( target == "_blank" ) {
                            blank = true;
                        }
                        if ( blank || ctrl ) {
                            window.open( url, '_blank' );
                        } else {
                            window.location = url;
                        }
                        return false;
                    }
                    
                    return false;
                    
                } );
            } else {
                $( document ).on( 'click', element, function ( e ) {
                    let blank  = false;
                    let url    = atob( $( this ).attr( 'data-obf' ) );
                    let target = $( this ).attr( 'data-obf-target' );
                    if ( target == "_blank" ) {
                        blank = true;
                    }
                    if ( blank ) {
                        window.open( url, '_blank' );
                    } else {
                        window.location = url;
                    }
                    return false;
                } );
            }
        }
    }

    static on_scroll()
    {
        let last_scroll_top = 0;
        let header_sticky_height = 0;

        $( window ).on( 'load', function () {

            if ( $('.header--large').length ) {
                header_sticky_height = ( $('.header--large').outerHeight() - $( '.navbar' ).outerHeight() );
            }
        });

        $( window ).on( 'load scroll', function () {
            
            const scroll_top = $( this ).scrollTop();

            if ( scroll_top >= last_scroll_top && scroll_top > $( '.navbar' ).outerHeight() ) {
                // Down
                $( 'body' ).addClass( 'is-header-hidden' );

                if($('.map').length > 0){
                    window.wpet_map_object.mapObject.resize();
                }
            } else {
                // Up
                $( 'body' ).removeClass( 'is-header-hidden' );

                if($('.map').length > 0){
                    window.wpet_map_object.mapObject.resize();
                }
            }
            last_scroll_top = scroll_top;

            // Site header filled
            if ( scroll_top > header_sticky_height ) {
                // Down
                $('body').addClass('is-header-filled');
            } else {
                // Up
                $('body').removeClass('is-header-filled');
            }
        } );
    }

    static ajax_instagram() {
        let $wrapper = $('[data-id="wpiris-instagram-wall"]');

        if ($wrapper.length > 0) {
            self = this;
            $.ajax({
                url: Theme.ajax_url,
                data: {
                    'action': 'social_wall',
                },
                type: 'POST',
                success: function (socialData) {
                    $wrapper.append(socialData);
                    Utils.lazy();
                }
            });
        }
    }

    static google_translate()
    {
        const trigger = '[data-trigger="glt-selector"]',
              $element = $('[data-id="glt-selector"]');

        $element.on( 'click', function () {

            if ( !$( event.target ).closest( '.gltSelectorInner' ).length ) {
                $( this ).fadeOut();
                updateCurrentLanguageToolbar();
            }
        } );

        $element.find( '.gltSelectorLangs a' ).on( 'click', function () {
            $element.fadeOut();
            updateCurrentLanguageToolbar();
        } );

        $( trigger ).on( 'click', function ( e ) {
            e.preventDefault();

            $element.fadeIn();
        } );

        var updateCurrentLanguageToolbar = function () {

            window.setTimeout( function () {

                var current_language = $( 'html' ).attr( 'lang' ).replace( /-.*/gi, '' );

                if ( current_language == 'auto' ) {
                    current_language = 'fr';
                }

                $( trigger ).text( current_language );
            }, 500 );
        };

        $( window ).on( 'load', function () {
            updateCurrentLanguageToolbar();
        } );
    }

    static progress_bar()
    {
        $( window ).scroll( function () {
            const $element       = $( '[data-trigger="wpiris-progressbar"]' );
            const s              = $( window ).scrollTop();
            const h              = $( document ).height();
            const c              = $( window ).height();
            const scroll_percent = (s / (h - c)) * 100;
            
            $element.attr( 'value', scroll_percent );
            $element.css( { 'width': scroll_percent + '%' } );
        } );
    }

    static blog_tags()
    {
        $( '.js-trigger-tags' ).on( 'click', function ( e ) {
            e.preventDefault();
            
            var total_to_show = $( '#list-tags' ).data( 'tags-to-show' );
            var $tags_hidden  = $( '#list-tags .cat-item.hidden' );
            var total_remains = $tags_hidden.length - total_to_show;
            
            $tags_hidden.slice( 0, total_to_show ).removeClass( 'hidden' );
            
            if ( total_remains < 1 ) {
                $( this ).remove();
            }
        } );
        
        $( '.js-trigger-blog-filter' ).on( 'click', function ( e ) {
            e.preventDefault();
            
            $( 'body' ).addClass( 'has-blog-filter-active' );
        } );

        $( '.js-trigger-blog-filter-close' ).on( 'click', function ( e ) {
            e.preventDefault();
            
            $( 'body' ).removeClass( 'has-blog-filter-active' );
        } );
    }

    static header_navbar()
    {
        $('.header .navbar').on('mouseenter', function(e) {
            $('body').addClass('is-navbar-hovered');
        });

        $('.header .navbar').on('mouseleave', function(e) {
            $('body').removeClass('is-navbar-hovered');
        });
    }

    static show_more()
    {
        const trigger = "[data-trigger='wpiris-show-more']";

        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();

            const attr = $( this ).attr( 'href' );

            $( attr ).toggleClass( 'show' );
            $( this ).hide();
        } );
    }

    static jquery_extends()
    {
        // toggleHtml
        $.fn.extend({
            toggleHtml: function(a, b){
                return this.html(this.html() == b ? a : b);
            }
        });
    }

    static toggle_tags()
    {
        const init = function( $element ) {

            $element.each( function() {
                const element_height = $(this).outerHeight();
                const ref_height = $(this).find('li').eq(0).outerHeight(true);

                if ( element_height > ref_height ) {
                    $(this).css({ height: ref_height });
                    $(this).siblings('.js-toggle-tags').first().show();
                }
            });
        }

        init( $('.wpetTags-toggle') );

        $(document).on('click', '.js-toggle-tags', function(e) {
            e.preventDefault();

            const $this = $(this);
            const $target = $this.siblings('.wpetTags-toggle');
            const label_more = $this.data('label-more');
            const label_less = $this.data('label-less');

            $target.toggleClass('is-expanded');
            $this.toggleHtml( label_more, label_less );
        });
    }

    static comments_rating()
    {
        if ( $( '.comment-form-vote .radio-wrapper' ).length ) {

            $( '.comment-form-vote .radio-wrapper input' ).on( 'click', function () {
                const $wrap = $( '.comment-form-vote .radio-wrapper' );
                const val  = $wrap.find( 'input[name="vote"]:checked' ).val();

                $wrap.removeClass( 'etat_1 etat_2 etat_3 etat_4 etat_5' );
                $wrap.addClass( 'etat_' + val );
            } );
        }
    }
}
