import Swiper from 'swiper';

export default class Responsive {

    static #breakpoints = {
        tablet: 1000,
        mobile: 650
    }
    static #hp_section_2_slider;

    constructor()
    {
        Responsive.init();
    }

    static init()
    {
        Responsive._init();
        Responsive.menu_swipe();
        Responsive.menu_swipe_on_resize();
        Responsive.hp_section_2_slider();

        $(window).on('resize', () => {
            Responsive._init();
            Responsive.menu_swipe_on_resize();
            Responsive.hp_section_2_slider();
        });
    }
    
    static _init()
    {
        // Tablet
        if ( window.matchMedia( "(max-width: " + Responsive.#breakpoints.tablet + "px)" ).matches ) {
            
            // Toolbar
            $('.tools .js-toolsMobile').appendTo('.site-menu__mobile__tools');

            // Homepage
            $('.hp-section-2 .entityList .hp-section__cta').appendTo('.hp-section-2');
            
        } else {
            
            // Toolbar
            $('.site-menu__mobile__tools .js-toolsMobile').insertAfter('.tools .toolsItem--contact');

            // Homepage
            $('.hp-section-2 > .hp-section__cta').appendTo('.hp-section-2 .entityList');
        }
        
        // Mobile
        if ( window.matchMedia("(max-width: " + Responsive.#breakpoints.mobile + "px)").matches ) {

        } else {

        }
    }

    static menu_swipe()
    {
        $('#site-menu__mobile__wrapper').on('click', '.trigger-nav-subcat', function(e) {
            e.preventDefault();
            $('#site-menu__mobile__wrapper .menu-item-has-children').not( $(this).parents('.is-active-subcat') ).removeClass('is-active-subcat');

            if ( !$(this).closest('.menu-item-has-children').hasClass('is-active-subcat') ) {
                $(this).closest('.menu-item-has-children').addClass('is-active-subcat');
            }
        });
        
        $('#site-menu__mobile__wrapper').on('click', '.trigger-nav-back', function(e) {
            e.preventDefault();
            $(this).closest('.is-active-subcat').removeClass('is-active-subcat');
        });

        $('.js-trigger-menu').on('click', function(e) {
            e.preventDefault();
            $('body').toggleClass('menu-revealed overlay-active');
        });
        
        $('#site-overlay').on('click', function(e) {
            e.preventDefault();
            $('body').removeClass('menu-revealed overlay-active');
        });
    }

    static menu_swipe_on_resize()
    {
        if ( window.matchMedia( "(max-width: " + Responsive.#breakpoints.tablet + "px)" ).matches ) {
            
            $('.trigger-nav-subcat').remove();
            $('#site-menu__mobile__wrapper .menu-item-has-children > a').prepend('<button type="button" class="trigger-nav-subcat" aria-label="' + Theme.translate.submenu + '"><i class="fi fi-arrow-right"></i></button>');
            $('.trigger-nav-back').remove();
            $('#site-menu__mobile__wrapper .sub-menu').prepend('<button type="button" class="button trigger-nav-back" aria-label="' + Theme.translate.back + '"><i class="fi fi-arrow-left"></i> ' + Theme.translate.back + '</button>');
            
        } else {
            
            $('.trigger-nav-subcat').remove();
            $('.trigger-nav-back').remove();
        }
    }

    static hp_section_2_slider()
    {
        const $slider = $('.js-hp-section-2-slider');

        if ( $slider.length ) {

            if ( window.matchMedia("(max-width: " + Responsive.#breakpoints.mobile + "px)").matches ) {

                if ( ! $slider.hasClass('swiper-container-initialized') ) {

                    $slider.addClass('swiper-container');
                    $slider.wrapInner('<div class="swiper-wrapper"></div>');
                    $slider.find('.entityItem').wrap('<div class="swiper-slide"></div>');
                    setTimeout(() => {
                        Responsive.#hp_section_2_slider = new Swiper( $slider, {
                            slidesPerView: 1,
                            mousewheel: {
                                forceToAxis: true,
                            },
                            speed: 600,
                            lazy: true,
                            spaceBetween: 20,
                            freeMode: true,
                            grabCursor: true
                        } );
                    }, 0);
                }

            } else {

                if ( $slider.hasClass('swiper-container-initialized') && Responsive.#hp_section_2_slider ) {

                    Responsive.#hp_section_2_slider.destroy();
                    $slider.removeClass('swiper-container');
                    $slider.find('.entityItem').unwrap().unwrap();
                }
            }
        }
    }
}
